import React from 'react';
import GitHubIcon from '@material-ui/icons/GitHub';

class Footer extends React.Component {

    openGithub = () => {
        window.open("https://github.com/twobitent/pokepocket-one");
    }

    render() {
        return (
            <>
                <div className="app__footer noselect">
                    <div>
                        <h1>

                            Why buy from PokePocket.com?
                        </h1>
                        <p>

                            Our Store:
                            SELLS, SHIPS, TOKENIZES & CELEBRATES YOUR POKEMON CARD COLLECTION!
                        </p>
                        <p>

                            #PokemonTCG #PokePocket #PokePocketCards #HarmonyONE $ONE
                        </p>
                        <p>

                            #TCG #Pokemon #GottaCatchEmAll #YourPokemonPocket
                        </p>
                        <p>

                            ---
                        </p>
                        <p>

                            This website is not produced,
                        </p>
                        <p>
                            endorsed, supported, or affiliated
                        </p>
                        <p>
                            with Nintendo or The Pokémon Company.
                        </p>
                    </div>
                </div>
            </>
        )
    }
}

export default Footer;