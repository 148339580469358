import React from 'react';
import Pokedex from "../assets/images/pokedex.png";

class Loading extends React.Component {

    openGithub = () => {
        window.open("https://github.com/twobitent/pokepocket-one");
    }

    render() {
        return (
            <>
                <div className="app__container">
                    <div className="loading__text">
                        Loading
                    </div>
                    <div className="gif__container">
                        <img src={Pokedex} alt="loading" className="loading__gif noselect" />

                    </div>
                </div>
            </>
        )
    }
}

export default Loading;